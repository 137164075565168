import { flip, computePosition, shift } from '@floating-ui/dom'
import { trackEvent } from '../util/analytics'

up.compiler('[translation]', async (element, { replaceEntirePage, pageType }) => {

  const languageSelectButtonSelector = 'button[translation--language-select-button]'

  async function onClickButton(event, button) {
    const languageSelectPath = button.dataset.languageSelectPath

    const layer = await up.layer.open({
      url: languageSelectPath,
      navigate: false,
      history: false,
      target: '.language-select',
      onAccepted: changeLanguage,
      origin: button,
      mode: 'popup',
      size: 'content',
    })

    computePosition(button, layer.element, {
      alignment: 'bottom',
      middleware: [
        flip({ padding: 10 }),
        shift({ padding: 10 }),
      ],
    }).then(async ({ x, y }) => {
      layer.element.style.left = `${x}px`
      layer.element.style.top = `${y}px`
    })
  }

  async function changeLanguage(event) {
    const url = new URL(up.fragment.source(element), window.location.href)
    if (event.value) {
      url.searchParams.set('translate', event.value)
    } else {
      url.searchParams.set('translate', 'disable')
    }

    if (replaceEntirePage) {
      up.navigate({ url: url.toString(), history: false })
    } else {
      const translationFields = [
        ...document.querySelectorAll('.translated-text--translation'),
        ...document.querySelectorAll('[translation--language-select-button]'),
      ].map((element) => up.fragment.toTarget(element))
      await up.render({ url: url.toString(), target: translationFields.join(', ') }).finished
      trackTranslation()
    }
  }

  function trackTranslation() {
    const translatedText = element.querySelector('.translated-text--translation')
    const language = translatedText.getAttribute('lang')

    if (language) {
      trackEvent('translation', pageType, language)
    }
  }

  trackTranslation()
  return up.on(element, 'click', languageSelectButtonSelector, onClickButton)
})
